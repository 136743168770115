.Trabaja {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Trabaja-quest {
  padding: 20px 0px;
  display: flex;
  align-items: flex-start;
  margin: 0;
  width: 60%;
  gap: 8px;
  padding-top: 20px;
}

.Trabaja-quest__number {
  display: flex;
  align-items: center;
  margin: 0;
  margin-right: 4px;
  margin-left: -50px;
  gap: 4px;
}

.Trabaja-quest__title {
  margin: auto 0;
  font-weight: 100;
  font-size: 14px;
}

.Trabaja-subtitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
}

.Trabaja-subtitle__text,
.Trabaja-subtitle__subtext {
  width: 100%;
  text-align: left;
  font-weight: 100;
  font-size: 14px;
  margin-bottom: 0;
}

.Trabaja-subtitle__subtext {
  color: gray;
  font-size: 12px;
}

.Trabaja-form {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.Trabaja-input {
  display: flex;
  justify-content: center;
  height: 250px;
  width: 100%;
  margin: 0px 0 20px 0;
}

.Trabaja-input__text {
  display: flex;
  min-width: 100%;
  margin: 0;
  height: 90%;
  justify-content: center;
  align-items: flex-end;
  font-size: 14px;
  z-index: -1;
}

.Trabaja-input__container {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 60%;
  background-image: url(../images/upload.svg);
  background-size: 150px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgb(212, 212, 212);
  border: 2px black dashed;
}

.Trabaja-input__container:hover {
  background-color: darkgray;
}

.Trabaja-input__input {
  height: 100%;
  width: 100%;
  display: block;
  font-family: inherit;
  color: rgb(0, 0, 0);
  outline: none;
  transform: translateZ(0px);
  -webkit-font-smoothing: antialiased;
  line-height: unset;
  -webkit-text-fill-color: rgb(0, 0, 0);
  animation: 1ms ease 0s 1 normal none running native-autofill-in;
  transition: background-color 1e8s ease 0s, box-shadow 0.1s ease-out 0s;
  background-color: transparent !important;
  -webkit-tap-highlight-color: transparent;
  opacity: 0;
}

.Trabaja-input__input::placeholder {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.7);
}

.Trabaja-submit {
  display: flex;
  width: 60%;
  gap: 16px;
}

.Trabaja-submit {
  display: flex;
  width: 60%;
  gap: 16px;
}
.Trabaja-file {
  margin: 20px 0 20px 0;
}

.Trabaja-spinner {
  position: relative;
  left: 10px;
  bottom: 4px;
  width: 49px;
  height: 20px;
}

.Trabaja-submit__button {
  -webkit-tap-highlight-color: transparent;
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  cursor: pointer;
  transition-duration: 0.1s;
  transition-property: background-color, color, border-color, opacity,
    box-shadow;
  transition-timing-function: ease-out;
  outline: none;
  border: 1px solid transparent;
  margin: 0px;
  box-shadow: rgb(0 0 0 / 10%) 0px 3px 12px 0px;
  padding: 0px 14px;
  min-height: 40px;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
}

.Trabaja-submit__text {
  margin: auto 0;
  font-size: 12px;
}

.Trabaja-quest__arrow,
.Trabaja-quest__ok {
  width: 20px;
  height: 20px;
}
