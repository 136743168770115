.Selector {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Selector-quest {
  padding: 20px 0px;
  display: flex;
  align-items: flex-start;
  margin: 0;
  width: 60%;
  gap: 8px;
}

.Selector-quest__number {
  display: flex;
  align-items: center;
  margin: 0;
  margin-right: 4px;
  margin-left: -50px;
  gap: 4px;
}

.Selector-quest__title {
  margin: auto 0;
  font-weight: 100;
  font-size: 14px;
}

.Selector-form {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.Selector-options {
  width: 60%;
  display: inline-flex;
  margin: 0px 0px -8px;
  list-style: none;
  padding: 0px;
  flex-flow: column wrap;
  -webkit-box-align: stretch;
  align-items: stretch;
  max-width: 100%;
  min-width: 168px;
  -webkit-tap-highlight-color: transparent;
  margin-bottom: 20px;
  gap: 6px;
}

.Selector-options__letter {
  border: 1.5px solid dimgray;
  padding: 3px 5px;
  margin: 4px;
  margin-right: 7px;
  background-color: white;
  border-radius: 4px;
}

.Selector-options__option {
  width: auto;
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  color: rgb(0, 0, 0);
  max-width: 100%;
  min-width: 75px;
  min-height: 40px;
  outline: 0px;
  padding: 0 4px;
  transition-duration: 0.1s;
  transition-property: background-color, color, border-color, opacity,
    box-shadow;
  transition-timing-function: ease-out;
  animation: 0.25s ease 0s 2 normal none running jBPXGM;
  cursor: pointer;
  opacity: 1;
  border: solid 1.5px black;
}

.Selector-submit {
  display: flex;
  width: 60%;
  gap: 16px;
}

.Selector-submit__button {
  -webkit-tap-highlight-color: transparent;
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  cursor: pointer;
  transition-duration: 0.1s;
  transition-property: background-color, color, border-color, opacity,
    box-shadow;
  transition-timing-function: ease-out;
  outline: none;
  border: 1px solid transparent;
  margin: 0px;
  box-shadow: rgb(0 0 0 / 10%) 0px 3px 12px 0px;
  padding: 0px 14px;
  min-height: 40px;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
}

.Selector-quest__arrow,
.Selector-quest__ok {
  width: 20px;
  height: 20px;
}

.Selector-options__option:hover,
.selected {
  background-color: rgba(0, 0, 0, 0.3);
}

.Selector-options__checkbox {
  visibility: hidden;
}

.Selector-spinner {
  position: relative;
  left: 10px;
  bottom: 4px;
  width: 49px;
  height: 20px;
}
