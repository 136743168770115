.Thanks {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Thanks-title{
    display: flex;
    font-size: 32px;
    font-weight: 100;
    width: 60%;
    margin: 75px 0;
    justify-content: center;
}

.Thanks-submit {
    display: flex;
    width: 60%;
    justify-content: center;
    gap: 16px;
}

.Thanks-submit__button {
    -webkit-tap-highlight-color: transparent;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    font-weight: 700;
    cursor: pointer;
    transition-duration: 0.1s;
    transition-property: background-color, color, border-color, opacity, box-shadow;
    transition-timing-function: ease-out;
    outline: none;
    border: 1px solid transparent;
    margin: 0px;
    box-shadow: rgb(0 0 0 / 10%) 0px 3px 12px 0px;
    padding: 0px 14px;
    min-height: 40px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
}

.Thanks-submit__text{
    margin: auto 0;
    font-size: 12px;
}


.Thanks-quest__ok {
    width: 20px;
    height: 20px;
}