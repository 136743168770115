.ProductSearch {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ProductSearch-quest{
    padding: 20px 0px;
    display: flex;
    align-items: flex-start;
    margin: 0;
    width: 60%;
    gap: 8px;
}

.ProductSearch-quest__number{
    display: flex;
    align-items: center;
    margin: 0;
    margin-right: 4px;
    margin-left: -50px;
    gap: 4px;
}

.ProductSearch-quest__title{
    margin: auto 0;
    font-weight: 100;
    font-size: 14px;
}

.ProductSearch-form {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.ProductSearch-input {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 20px;
}
.ProductSearch-options {
    width: 100%;
    max-height: 123px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    margin: 0px 0px -8px;
    list-style: none;
    padding: 0px;
    -webkit-box-align: stretch;
    align-items: stretch;
    max-width: 100%;
    min-width: 168px;
    -webkit-tap-highlight-color: transparent;
    margin-bottom: 20px;
}

.ProductSearch-options__letter {
    border: 1.5px solid dimgray;
    padding: 3px 5px;
    margin: 4px;
    margin-right: 7px;
    background-color: white;
    border-radius: 4px;
}

.ProductSearch-options__option {
    width: auto;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.1);
    color: rgb(0, 0, 0);
    max-width: 100%;
    min-width: 75px;
    min-height: 40px;
    outline: 0px;
    padding: 0 4px;
    transition-duration: 0.1s;
    transition-property: background-color, color, border-color, opacity, box-shadow;
    transition-timing-function: ease-out;
    animation: 0.25s ease 0s 2 normal none running jBPXGM;
    cursor: pointer;
    opacity: 1;
    border: solid 0.5px gray;
    font-size: 12px;
    gap: 16px;
    padding-left: 12px;
}
.ProductSearch-input__input {
    width: 100%;
    border: none;
    display: block;
    width: 100%;
    font-family: inherit;
    color: rgb(0, 0, 0);
    padding: 0px 0px 8px;
    outline: none;
    appearance: none;
    background-image: none;
    background-position: initial;
    background-size: initial;
    background-repeat: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    transform: translateZ(0px);
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    line-height: unset;
    -webkit-text-fill-color: rgb(0, 0, 0);
    animation: 1ms ease 0s 1 normal none running native-autofill-in;
    transition: background-color 1e+08s ease 0s, box-shadow 0.1s ease-out 0s;
    background-color: transparent !important;
    -webkit-tap-highlight-color: transparent;
    border-bottom: 1px solid;
    margin-bottom: 5px;
}

.ProductSearch-input__input::placeholder{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    -webkit-text-fill-color: rgba(0, 0, 0, 0.7);
}

.ProductSearch-submit {
    display: flex;
    gap: 16px;
}

.ProductSearch-submit__button {
    -webkit-tap-highlight-color: transparent;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    font-weight: 700;
    cursor: pointer;
    transition-duration: 0.1s;
    transition-property: background-color, color, border-color, opacity, box-shadow;
    transition-timing-function: ease-out;
    outline: none;
    border: 1px solid transparent;
    margin: 0px;
    box-shadow: rgb(0 0 0 / 10%) 0px 3px 12px 0px;
    padding: 0px 14px;
    min-height: 40px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
}

.ProductSearch-quest__arrow,
.ProductSearch-quest__ok {
    width: 20px;
    height: 20px;
}

.ProductSearch-option__image{
    height: 30px;
    width: 30px;
    border: 1.5px solid darkgray;
    border-radius: 4px;
}