/* .App,
#root,
.Processor{
    height: 100%;
} */

.back-arrow {
  border-radius: 4px;
  cursor: pointer;
  border: none;
  box-shadow: none;
  display: flex;
  margin-top: 70px;
  position: relative;
  top: 2%;
  left: 7%;
  height: 30px;
  width: 100px;
  font-size: 12px;
  font-weight: 400;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  background-color: black;
}

.back-arrow > img {
  color: white;
  max-width: 10px;
  max-height: 10px;
}
