.TextInput {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.TextInput-quest{
    padding: 20px 0px;
    display: flex;
    align-items: flex-start;
    margin: 0;
    width: 60%;
    gap: 8px;
    padding-top: 20px;
}

.TextInput-quest__number{
    display: flex;
    align-items: center;
    margin: 0;
    margin-right: 4px;
    margin-left: -50px;
    gap: 4px;
}

.TextInput-quest__title{
    margin: auto 0;
    font-weight: 100;
    font-size: 14px;
}

.TextInput-subtitle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%; 
}

.TextInput-subtitle__text,
.TextInput-subtitle__subtext{
    width: 100%;
    text-align: left;
    font-weight: 100;
    font-size: 14px;
    margin-bottom: 0;
}

.TextInput-subtitle__subtext{
    color: gray;
    font-size: 12px;
}

.TextInput-form {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.TextInput-input {
    width: 100%;
    margin: 20px 0 15px 0;
}

.TextInput-input__input {
    width: 100%;
    border: none;
    border-bottom: 1px solid;
    display: block;
    width: 100%;
    font-family: inherit;
    color: rgb(0, 0, 0);
    border: none;
    outline: none;
    border-radius: 0px;
    appearance: none;
    background-image: none;
    background-position: initial;
    background-size: initial;
    background-repeat: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    transform: translateZ(0px);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    line-height: unset;
    -webkit-text-fill-color: rgb(0, 0, 0);
    animation: 1ms ease 0s 1 normal none running native-autofill-in;
    transition: background-color 1e+08s ease 0s, box-shadow 0.1s ease-out 0s;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px;
    background-color: transparent !important;
    -webkit-tap-highlight-color: transparent;
}

.TextInput-input__input::placeholder{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    -webkit-text-fill-color: rgba(0, 0, 0, 0.7);
}

.TextInput-submit {
    display: flex;
    width: 60%;
    gap: 16px;
}

.TextInput-submit__button {
    -webkit-tap-highlight-color: transparent;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    font-weight: 700;
    cursor: pointer;
    transition-duration: 0.1s;
    transition-property: background-color, color, border-color, opacity, box-shadow;
    transition-timing-function: ease-out;
    outline: none;
    border: 1px solid transparent;
    margin: 0px;
    box-shadow: rgb(0 0 0 / 10%) 0px 3px 12px 0px;
    padding: 0px 14px;
    min-height: 40px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
}

.TextInput-submit__text{
    margin: auto 0;
    font-size: 12px;
}

.TextInput-quest__arrow,
.TextInput-quest__ok {
    width: 20px;
    height: 20px;
}

.TextInput-input__error{
    margin: 0;
    margin-top: 5px;
    min-height: 20px;
    font-size: 10px;
    color: red;
}

.input-error{
    box-shadow: rgb(255 0 0 / 100%) 0px 1px;
}